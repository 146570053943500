import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import { useMediaQuery } from 'common/utils';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useMemo, useState } from 'react';
import { UserPopup, UserPopupAlertDefine } from 'common/interfaces/api';

const InputContactEmailDialog = dynamic(
  () => import('components/common/InputContactEmailDialog'),
  {
    ssr: false,
  }
);

const AskAgeModal = dynamic(() => import('components/AskAgeModal'), {
  ssr: false,
});

const InviteFriendModal = dynamic(
  () => import('components/common/InviteFriendModal'),
  {
    ssr: false,
  }
);

const ProfileHelpDialog = dynamic(
  () => import('components/common/ProfileHelpDialog'),
  {
    ssr: false,
  }
);

const OnboardingSection = dynamic(
  () => import('components/OnboardingSection'),
  {
    ssr: false,
  }
);

const MissionsDialog = dynamic(() => import('components/MissionsDialog'), {
  ssr: false,
});

const LevelUpModal = dynamic(() => import('components/LevelUpModal'), {
  ssr: false,
});

const AnimeCharacterLevelChangePopup = dynamic(
  () => import('components/common/AnimeCharacterLevelChangePopup'),
  {
    ssr: false,
  }
);

const DiscountCreatorsDialog = dynamic(
  () => import('components/common/DiscountCreatorsDialog'),
  {
    ssr: false,
  }
);

const CoinSummaryDialog = dynamic(
  () => import('components/CoinSummaryDialog'),
  {
    ssr: false,
  }
);

const UserPopupAlert = dynamic(() => import('components/UserPopupAlert'), {
  ssr: false,
});

const InputProfileMissionDialog = dynamic(
  () => import('components/InputProfileMissionDialog'),
  {
    ssr: false,
  }
);

const RemainingCoinDialog = dynamic(
  () => import('components/common/RemainingCoinDialog'),
  {
    ssr: false,
  }
);

const AnimeCharacterOnboardingDialog = dynamic(
  () => import('components/AnimeCharacterOnboardingDialog'),
  {
    ssr: false,
  }
);

const AnimeCharacterSettingsDialog = dynamic(
  () => import('components/AnimeCharacterSettingsDialog'),
  {
    ssr: false,
  }
);

const CreatePostBoxFullScreen = dynamic(
  () => import('components/CreatePostBoxFullScreen'),
  {
    ssr: false,
  }
);

const CreatePostBoxFullScreenMobile = dynamic(
  () => import('components/CreatePostBoxFullScreenMobile'),
  {
    ssr: false,
  }
);

const InputCodeModal = dynamic(
  () => import('components/common/InputCodeModal'),
  {
    ssr: false,
  }
);

const RecommendCreatorDialog = dynamic(
  () => import('components/common/RecommendCreatorDialog'),
  {
    ssr: false,
  }
);

const SymbolAlertSettingDialog = dynamic(
  () => import('components/common/SymbolAlertSettingDialog'),
  {
    ssr: false,
  }
);

const MembershipSubscribingStatusModal = dynamic(
  () => import('components/organisms/MembershipSubscribingStatusModal'),
  {
    ssr: false,
  }
);

const IzanaviPRDialog = dynamic(
  () => import('components/organisms/IzanaviPRDialog'),
  {
    ssr: false,
  }
);

const SharePostPopup = dynamic(
  () => import('components/organisms/SharePostPopup'),
  {
    ssr: false,
  }
);

const AllModalsSection = ({
  isFetchedPopups,
}: {
  isFetchedPopups: boolean;
}) => {
  const router = useRouter();
  const isNursingLP = router.pathname?.startsWith('/lp/kaigoprime');
  const isVerifyPage = router.pathname?.startsWith('/mails/verify');
  const isHelpPage =
    router.pathname?.startsWith('/help') ||
    router.pathname?.startsWith('/privacy_policy');

  if (isNursingLP || isHelpPage || isVerifyPage) return <></>;
  return (
    <>
      <OpenByActionModals />
      {isFetchedPopups && <AutoOpenByBEModals />}
    </>
  );
};

const OpenByActionModals = () => {
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  // const router = useRouter();
  // const isCoursesPage = router.pathname.startsWith(`/courses`);
  // const isMembershipPage = router.pathname === '/p/membership';
  const showingMissionDialog = useGlobalStore(
    (state) => state.showingMissionDialog
  );
  const showRecommendCreatorInput = useGlobalStore(
    (state) => state.showRecommendCreatorInput
  );
  const showSymbolAlertSettingDialogInput = useGlobalStore(
    (state) => state.showSymbolAlertSettingDialogInput
  );
  const isShowingContactEmailInputDialog = useGlobalStore(
    (state) => state.isShowingContactEmailInputDialog
  );
  const showingInputReferralCodeDialog = useGlobalStore(
    (state) => state.showingInputReferralCodeDialog
  );
  const isShowingRemainingCoinDialog = useGlobalStore(
    (state) => state.isShowingRemainingCoinDialog
  );
  const showingInviteFriendDialog = useGlobalStore(
    (state) => state.showingInviteFriendDialog
  );
  const showingDiscountCreatorsDialog = useGlobalStore(
    (state) => state.showingDiscountCreatorsDialog
  );
  const showingInputProfileDialog = useGlobalStore(
    (state) => state.showingInputProfileDialog
  );
  const showingAnimeOnboardingDialog = useGlobalStore(
    (state) => state.showingAnimeOnboardingDialog
  );
  const showingAnimeSettingDialogInput = useGlobalStore(
    (state) => state.showingAnimeSettingDialogInput
  );
  const showingProfileHelpDialog = useGlobalStore(
    (state) => state.showingProfileHelpDialog
  );
  const showingCoinSummaryDialog = useGlobalStore(
    (state) => state.showingCoinSummaryDialog
  );
  const showCreatePostDialogInput = useGlobalStore(
    (state) => state.showCreatePostDialogInput
  );
  const showCreatePostDialogInputMobile = useGlobalStore(
    (state) => state.showCreatePostDialogInputMobile
  );
  const showingMembershipStatusDialog = useGlobalStore(
    (state) => state.showingMembershipStatusDialog
  );
  const showingIzanaviPRDialog = useGlobalStore(
    (state) => state.showingIzanaviPRDialog
  );
  const postToShareByPopup = useGlobalStore(
    (state) => state.postToShareByPopup
  );

  return (
    <>
      {showingMissionDialog && <MissionsDialog />}
      {showRecommendCreatorInput?.open && <RecommendCreatorDialog />}
      {showSymbolAlertSettingDialogInput?.open && <SymbolAlertSettingDialog />}
      {isShowingContactEmailInputDialog && <InputContactEmailDialog />}
      {showingInputReferralCodeDialog && <InputCodeModal />}
      {isShowingRemainingCoinDialog && <RemainingCoinDialog />}
      {showingInviteFriendDialog && <InviteFriendModal />}
      {showingDiscountCreatorsDialog && <DiscountCreatorsDialog />}
      {showingInputProfileDialog && <InputProfileMissionDialog />}
      {showingAnimeOnboardingDialog && <AnimeCharacterOnboardingDialog />}
      {showingAnimeSettingDialogInput?.open && <AnimeCharacterSettingsDialog />}
      {showingProfileHelpDialog && <ProfileHelpDialog />}
      {showingCoinSummaryDialog && <CoinSummaryDialog />}
      {showingMembershipStatusDialog && <MembershipSubscribingStatusModal />}
      {showingIzanaviPRDialog && <IzanaviPRDialog />}
      {postToShareByPopup && <SharePostPopup />}
      {/* don't show popup with redirect button for live stream page */}
      {!isMobileScreen && showCreatePostDialogInput?.open && (
        <CreatePostBoxFullScreen />
      )}
      {isMobileScreen && showCreatePostDialogInputMobile?.open && (
        <CreatePostBoxFullScreenMobile />
      )}
      {/* don't show campaign popup in membership page + course page */}
      <LevelUpModal />
      <AnimeCharacterLevelChangePopup />
    </>
  );
};

// show modals order
// ask age
// onboarding
// after 10min => show campaign popup
const AutoOpenByBEModals = () => {
  const [showedPopupIds, setShowedPopupIds] = useState<number[]>([]);
  const currentUser = useStore((state) => state.currentUser);
  const allUserPopups = useGlobalStore((state) => state.allUserPopups);
  const [askAgePopup, setAskAgePopup] = useState<UserPopup | null>(() => {
    return allUserPopups?.find(
      (p) => p.type === UserPopupAlertDefine.InputIsAdult
    );
  });

  const isCreatedAccount10Min = useMemo(() => {
    if (!currentUser?.created_at) return false;
    return (
      new Date(currentUser?.created_at)?.getTime() <
      new Date().getTime() - 10 * 60 * 1000
    );
  }, [currentUser?.created_at]);
  if (askAgePopup)
    return <AskAgeModal popup={askAgePopup} setPopup={setAskAgePopup} />;
  if (currentUser?.on_boarding || currentUser?.need_new_onboarding)
    return <OnboardingSection />;
  if (!isCreatedAccount10Min) return <></>;
  return (
    <>
      {/* add logic for check age modal */}
      {allUserPopups && allUserPopups?.length > 0 && (
        <UserPopupAlert
          showedPopupIds={showedPopupIds}
          setShowedPopupIds={setShowedPopupIds}
        />
      )}
    </>
  );
};

export default AllModalsSection;
